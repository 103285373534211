import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy
} from '@angular/core';
import { MAINROUTES } from './sidebar-items';

import { AuthService } from 'src/app/core/service/auth.service';
import { UnsubscribeOnDestroyAdapter } from 'src/app/shared/UnsubscribeOnDestroyAdapter';
import { DataManagmentService } from 'src/app/shared/_service/data-managment.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass']
})
export class SidebarComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit, OnDestroy {
  public sidebarItems: any[];
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  headerHeight = 60;
  routerObj = null;
  userType;
  userData: any;
  currentActiveElem: HTMLElement;

  typeOfUser = {
    admin: 'HCP Admin'
  };

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private GLOBAL: DataManagmentService
  ) {
    super();
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // close sidebar after menu select
        this.renderer.removeClass(this.document.body, 'overlay-open');
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      }
    });
  }
  @HostListener('window:resize', ['$event'])
  windowResizecall(event) {
    this.setMenuHeight();
    //this.checkStatuForResize(false);
  }
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, 'overlay-open');
    }
  }

  callToggleMenu(event: any, length: any) {
    if (length > 0) {
      const parentElement = event.target.closest('li');
      const activeClass = parentElement.classList.contains('active');

      if (activeClass) {
        this.renderer.removeClass(parentElement, 'active');
      } else {
        if (this.currentActiveElem) {
          this.renderer.removeClass(this.currentActiveElem, 'active');
        }
        this.renderer.addClass(parentElement, 'active');
        this.currentActiveElem = parentElement;
      }
    }
  }

  ngOnInit() {
    //TODO: rimuovere this.sidebarItems = MAINROUTES; una volta implementate le url finali
    this.sidebarItems = MAINROUTES;
    this.GLOBAL.currentUserData.subscribe(x => {
      if (Object.keys(x).length > 0) {
        
        // retrive user data from service

        let available = [];
        let array= MAINROUTES


        array.forEach((sidebarItem) => {
          sidebarItem.isVisibleFor.forEach((e) => {
            // First Level
            // e values in isVisibleFor
            // sidebarItem full object
            // x user
            // check role and brand
            if (x && e == x.role.value) {
              if (sidebarItem.submenu.length != 0) {
                let subArray = []
                sidebarItem.submenu.forEach((sub, i, a) => {
                  // Second Level

                  sub.isVisibleFor.forEach((t) => {
                    // check role and brand
                    if (t == x.role.value) {
                      subArray.push(sub)
                    }
                  });
                });
                sidebarItem.submenu = subArray;
              }

              available.push(sidebarItem);
            }
          });
        });
        this.sidebarItems = available;

        this.userData = x;
        console.log(x.role)
        this.userType = x.role;

      }



    })



    this.initLeftSidebar();
    this.bodyTag = this.document.body;

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.mouseOut();
      }
    })
  }

  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }
  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    //_this.checkStatuForResize(true);
  }
  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + '';
    this.listMaxWidth = '500px';
  }
  isOpen() {
    return this.bodyTag.classList.contains('overlay-open');
  }
  checkStatuForResize(firstTime) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, 'ls-closed');
    } else {
      this.renderer.removeClass(this.document.body, 'ls-closed');
    }
  }
  logout(){
    this.authService.logout()
  }

  isEmpty(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  mouseHover() {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('submenu-closed')) {
      this.renderer.addClass(this.document.body, 'side-closed-hover');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    }
  }
  mouseOut() {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('side-closed-hover')) {
      this.renderer.removeClass(this.document.body, 'side-closed-hover');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }
}
