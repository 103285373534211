import { Component, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { DatePipe } from '@angular/common';

import { UrlService } from '../../_service/url.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dialog-overlay',
  templateUrl: './dialog-overlay.component.html',
  styleUrls: ['./dialog-overlay.component.scss']
})
export class DialogOverlayComponent {
  kind: string;
  status: string;
  element: string;
  data = new Subject<{ action: string, kind?: string, label?: string, data?: any }>;
  popupData;

  constructor(
    public dialogRef: MatDialogRef<DialogOverlayComponent>,
    public cdref: ChangeDetectorRef,
    private url: UrlService
  ) {
    dialogRef.disableClose = true;

    this.data.subscribe(value => {
      this.popupData = value;
      console.log(this.popupData);
    })
  }

  bindData(bindedData: { action: string, kind?: string, label?: string, data?: any }): void {
    console.log(bindedData)
    this.data.next(bindedData);
  }

  onNoClick(): void {
    this.dialogRef.close('onNoClick - close');
  }

  submitUserChoice(choice: boolean, action?, label?, kind?, result?: any): void {
    this.dialogRef.close({
      action: action ? action : null,
      kind: kind ? kind : null,
      label: label ? label : null,
      status: choice,
      data: result ? result : null,
    });
  }

}
