import { Component, EventEmitter, Input, Output, ViewChild, AfterViewInit, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';


export enum TableExporterColumnType {
  PLAIN_TEXT = 'PLAIN_TEXT', // Modalità da usare quando si vuole inserire all'interno della cella un testo
  HTML_TEXT = 'HTML_TEXT', // Modalità da usare quando si vuole inserire all'interno della cella del codice HTML
  STATUS = 'STATUS', // Modalità da usare quando si vuole inserire all'interno della cella un campo di tipo stato
  BOOL = 'BOOL', // Modalità da usare quando si vuole inserire all'interno della cella un campo di tipo Booleano
  CURRENCY = 'CURRENCY', // Modalità da usare quando si vuole inserire all'interno della cella un campo di tipo valuta
  ACTION = 'ACTION', // Modalità da usare quando si vuole inserire all'interno della cella un pulsante per effettuare un'azione
  LOADER = 'LOADER' // Modalità da usare quando si vuole inserire all'interno della cella un pulsante per effettuare un'azione
}

/**
 * Interfaccia di configurazione delle colonne in tabella.
 * @param id - ID della colonna (campo matColumnDef del ng-container)
 * @param header - Testo da mostrare come header della colonna
 * @param fieldName - Nome del campo su cui lavorare all'interno della cella
 * @param headerClassList - Lista delle classi da applicare all'header della tabella in corrispondenza della colonna
 * @param fieldClassList - Lista delle classi da applicare all'elemento della tabella in corrispondenza della colonna
 * @param sortable - Specifica se la colonna è ordinabile o meno
 * @param sortField - Opzionale. Specifica il nome del campo su cui filtrare se la colonna è filtrabile
 * @param tooltip - Opzionale. Specifica il nome del campo su cui filtrare se la colonna è filtrabile
 * @param type - Tipo di colonna da inserire ({@link TableExporterColumnType})
 * @param showMobile - Opzionale. Specifica se nascondere la colonna nella vista mobile
 * @param actions - Opzionale. Array di {@link TableAction} da includere nella colonna di tipo ACTION
 * @param hasTransformer - Booleano per controllare se bisogna applicare la transformFieldFunction o meno
 * @param transformFieldFunction - Opzionale. Funzione che opera come una pipe sul valore del campo indicizzato dal fieldName. Prende in input il valore (es. row[fieldname]) e deve restituire una stringa.
 */
export interface TableExporterColumnConfiguration {
  id: string;
  header: string;
  fieldName: string;
  headerClassList: string;
  fieldClassList: string;
  sortable: boolean;
  sortField?: string;
  tooltip?: string;
  type: TableExporterColumnType;
  showMobile?: boolean;
  actions?: TableAction[];
  hasTransformer: boolean;
  transformFieldFunction?: (object: any) => string | number | boolean;
  loaderOption?:string
}

/**
 * Interfaccia di configurazione per export server-side
 * @param columnName - Specifica il nome della colonna esportata
 * @param exportFieldName - Nome del campo con percorso completo in base alla relazione dell'entità, utilizzato per specificare i campi nell'export server-side (es. market.title)
 * @param exportDateFormat - Opzionale. Specifica che tipo di dato estrapolare dalla data per l'export server-side
 */
export interface TableExportConfiguration {
  columnName: string; // Specifica il nome della colonna esportata
  exportFieldName: string; // Nome del campo con percorso completo in base alla relazione dell'entità, utilizzato per specificare i campi nell'export server-side (es. market.title)
  exportDateFormat?: 'DATE' | 'TIME'; // Specifica che tipo di dato estrapolare dalla data per l'export server-side
}

/**
 * Interfaccia di configurazione per le colonne di tipo ACTION
 * @param actionIcon - Nome della mat-icon da usare all'interno del campo action (es. edit)
 * @param performAction - Funzione che viene eseguita in corrispondenza del click sul bottone dell'azione. Prende in input l'evento di click, l'id della colonna, l'indice della riga e l'oggetto (es. row[fieldname]) su cui si basa la riga
 */
export interface TableAction {
  actionIcon: string; // Nome della mat-icon da usare all'interno del campo action (es. edit)
  performAction: (event: Event, columnID: string, index: number, object: any) => void | string; // Funzione che viene eseguita in corrispondenza del click sul bottone dell'azione. Prende in input l'evento di click, l'id della colonna, l'indice della riga e l'oggetto (es. row[fieldname]) su cui si basa la riga
}

export interface TableExporterSortEvent {
  active: string;
  direction: string;
}

export interface TableExporterPaginationEvent {
  previousPageIndex: number,
  pageIndex: number,
  pageSize: number,
  length: number
}

@Component({
  selector: 'app-generic-exporter-table',
  templateUrl: './generic-exporter-table.component.html',
  styleUrls: ['./generic-exporter-table.component.scss']
})
export class GenericExporterTableComponent implements AfterViewInit, OnInit, OnChanges {
  @Input() tableName: string;
  @Input() data: any[][];
  @Input() set configuration(columns: TableExporterColumnConfiguration[]) {
    console.log('Configuration loaded:', columns);
    this._configuration = columns;
    this.displayedColumns = columns.map((col) => col.id);
  }
  @Input() formUrl: string;
  @Input() totalResults = 0;
  @Input() currentIndex: number = 0;
  @Input() exportColumnWidths: number[];
  @Input() exportHiddenColumns: number[];
  @Output() pageSizeChange = new EventEmitter<number>();
  @Output() indexChange = new EventEmitter<number>();
  @Output() sortData = new EventEmitter<TableExporterSortEvent>();
  @Output() loadNextPage = new EventEmitter<string>();
  @Output() exportClicked = new EventEmitter();

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  displayedColumns: string[] = [];
  currentPageSize: number = 20;
  _configuration: TableExporterColumnConfiguration[];
  resultsLength = 0;
  oldPageSize = 0;

  modifyUrl: string;

  constructor() { }

  ngOnInit(): void {
    this.modifyUrl = `/${this.formUrl}/`;
  }

  ngAfterViewInit(): void {
    this.oldPageSize = this.paginator.pageSize;

    if (window.sessionStorage.getItem(`[${this.tableName} sort] Column`)) {
      let sorting: TableExporterSortEvent = {
        active: window.sessionStorage.getItem(`[${this.tableName} sort] Column`),
        direction: window.sessionStorage.getItem(`[${this.tableName} sort] Direction`)
      }

      setTimeout(() => {
        this.sort.sort({
          id: sorting.active,
          start: sorting.direction as SortDirection,
          disableClear: false
        });
      }, 1000);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentIndex && this.paginator) {
      this.paginator.pageIndex = this.currentIndex;
    }
  }

  simpleSort(event: TableExporterSortEvent) {
    this.paginator.pageIndex = 0;
    this.indexChange.emit(0);
    this.sortData.emit(event);
  }

  setPagination(event: TableExporterPaginationEvent) {
    this.indexChange.emit(event.pageIndex);
    this.currentPageSize = event.pageSize;
    if (event.pageIndex > event.previousPageIndex) {
      if (event.pageIndex === event.previousPageIndex + 1) {
        this.loadNextPage.emit('next');
      } else {
        this.loadNextPage.emit('last');
      }
    } else if (event.pageIndex < event.previousPageIndex) {
      if (event.pageIndex === event.previousPageIndex - 1) {
        this.loadNextPage.emit('prev');
      } else {
        this.loadNextPage.emit('first');
      }
    }

    if (event.pageSize !== this.oldPageSize) {
      this.oldPageSize = event.pageSize;
      this.pageSizeChange.emit(this.oldPageSize);
      if (this.currentIndex > 0) {
        this.paginator.firstPage();
      }
    }
  }
}
