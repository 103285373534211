<ng-container *ngIf="popupData.kind == 'supplier-order'">
<h1 mat-dialog-title>Dettaglio Giacienza</h1>
  <div mat-dialog-content>
    <section class="border border-1 rounded p-1 mb-4" *ngIf="popupData['mainData'] && popupData['mainData'].length != 0">
      <div class="row pb-2">
          <div class="col d-flex align-items-center justify-content-between">
              

      <table class="table">
          <thead>
              <tr>
              <th scope="col">Rif Ordine</th>
              <th scope="col">Fornitore</th>
              <th scope="col">N Lotto Fornitore</th>
              <th scope="col">Quantità</th>
              <th scope="col">Data Scadenza</th>
              <th scope="col">Data Carico</th>
              <th scope="col">Prezzo Acquisto</th>
              <th scope="col">Prezzo di Listino</th>
              </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let choice of popupData['mainData']; let i=index">
                      <th scope="row">{{choice['rifOrdine']}}</th>
                      <td>{{choice['fornitore']}}</td>
                      <td>{{choice['lottoIdFornitore']}}</td>
                      <td>{{choice['quantita']}}</td>
                      <td>{{choice['dataScadenza']}}</td>
                      <td>{{choice['dataCarico']}}</td>
                      <td>{{choice['prezzoAcquisto']}} €</td>
                      <td>{{choice['prezzoListino']}} €</td>
                  </tr>
              </tbody>
          </table>
        </div>
      </div>
  </section>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close cdkFocusInitial>No</button>
    <button mat-button mat-dialog-close (click)="submitUserChoice(true)">Yes</button>
  </div>
</ng-container>