import { RouteInfo } from './sidebar.metadata';

export const MAINROUTES: RouteInfo[] = [
  {
    path: '/app/main',
    title: 'Welcome page',
    icon: 'home',
    class: '',
    groupTitle: false,
    isVisibleFor: ['administrator', 'manager','content_editor'],
    submenu: [],

  },
  {
    path: '',
    title: '-- Platform',
    icon: '',
    class: '',
    groupTitle: true,
    isVisibleFor: ['administrator', 'manager','content_editor'],
    submenu: [],
  },
  {
    path: '',
    title: 'Gestione Articoli e Prodotti',
    icon: 'shopping-bag',
    class: 'menu-toggle',
    groupTitle: false,
    isVisibleFor: ['administrator', 'manager','content_editor'],
    submenu: [
      {
        path: '/app/items',
        title: 'Articoli',
        icon: 'check-circle',
        class: '',
        groupTitle: false,
        isVisibleFor: ['administrator', 'manager','content_editor'],
        submenu: [],

      },
      {
        path: '/app/products',
        title: 'Prodotti',
        icon: 'check-circle',
        class: '',
        groupTitle: false,
        isVisibleFor: ['administrator', 'manager','content_editor'],
        submenu: [],

      }]
  },
  {
    path: '',
    title: 'Gestione Clienti',
    icon: 'users',
    class: 'menu-toggle',
    groupTitle: false,
    isVisibleFor: ['administrator', 'manager','content_editor'],
    submenu: [
      {
        path: '/app/orders',
        title: 'Ordini Clienti',
        icon: 'arrow-up',
        class: '',
        groupTitle: false,
        isVisibleFor: ['administrator', 'manager','content_editor'],
        submenu: [],

      },
      {
        path: '/app/clients',
        title: 'Clienti',
        icon: 'users',
        class: '',
        groupTitle: false,
        isVisibleFor: ['administrator', 'manager','content_editor'],
        submenu: [],

      },
      {
        path: '/app/clients-addresses',
        title: 'Indirizzi Clienti',
        icon: 'users',
        class: '',
        groupTitle: false,
        isVisibleFor: ['administrator', 'manager','content_editor'],
        submenu: [],

      },
      {
        path: '/app/offers',
        title: 'Offerte',
        icon: 'check-circle',
        class: '',
        groupTitle: false,
        isVisibleFor: ['administrator', 'manager','content_editor'],
        submenu: [],
      }
    ]
  },
  {
    path: '',
    title: 'Gestione Trasmissioni',
    icon: 'shuffle',
    class: 'menu-toggle',
    groupTitle: false,
    isVisibleFor: ['administrator', 'manager','content_editor'],
    submenu: [
      {
        path: '/app/transfers',
        title: 'Trasmissioni Ordini Clienti',
        icon: 'check-circle',
        class: '',
        groupTitle: false,
        isVisibleFor: ['administrator', 'manager','content_editor'],
        submenu: []
      },
      {
        path: '/app/suppliers-transfers',
        title: 'Trasmissioni Ordini Fornitori',
        icon: 'check-circle',
        class: '',
        groupTitle: false,
        isVisibleFor: ['administrator', 'manager','content_editor'],
        submenu: []
      },
      {
        path: '/app/direct-transfers',
        title: 'Trasmissioni Rimesse Dirette',
        icon: 'check-circle',
        class: '',
        groupTitle: false,
        isVisibleFor: ['administrator', 'manager','content_editor'],
        submenu: []
      },
      {
        path: '/app/reso-transfers',
        title: 'Trasmissioni Reso',
        icon: 'check-circle',
        class: '',
        groupTitle: false,
        isVisibleFor: ['administrator', 'manager','content_editor'],
        submenu: []
      },
      {
        path: '/app/aggregate-transfers',
        title: 'Elenco Evadibili',
        icon: 'check-circle',
        class: '',
        groupTitle: false,
        isVisibleFor: ['administrator', 'manager','content_editor'],
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Gestione Fornitori',
    icon: 'truck',
    class: 'menu-toggle',
    groupTitle: false,
    isVisibleFor: ['administrator', 'manager','content_editor'],
    submenu: [
      {
        path: '/app/supplier-orders',
        title: 'Ordini Fornitori',
        icon: 'check-circle',
        class: '',
        groupTitle: false,
        isVisibleFor: ['administrator', 'manager','content_editor'],
        submenu: []
      },
      {
        path: '/app/suppliers',
        title: 'Fornitori',
        icon: 'check-circle',
        class: '',
        groupTitle: false,
        isVisibleFor: ['administrator', 'manager','content_editor'],
        submenu: []
      },
      {
        path: '/app/ricezioni',
        title: 'Ricezioni',
        icon: 'check-circle',
        class: '',
        groupTitle: false,
        isVisibleFor: ['administrator', 'manager','content_editor'],
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Resi',
    icon: 'corner-down-left',
    class: 'menu-toggle',
    groupTitle: false,
    isVisibleFor: ['administrator', 'manager','content_editor'],
    submenu: [
      {
        path: '/app/resi/fornitore',
        title: 'Resi Fornitore',
        icon: 'check-circle',
        class: '',
        groupTitle: false,
        isVisibleFor: ['administrator', 'manager','content_editor'],
        submenu: []
      },
      {
        path: '/app/resi/cliente',
        title: 'Resi Cliente',
        icon: 'check-circle',
        class: '',
        groupTitle: false,
        isVisibleFor: ['administrator', 'manager','content_editor'],
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Gestione Agenti',
    icon: 'briefcase',
    class: 'menu-toggle',
    groupTitle: false,
    isVisibleFor: ['administrator', 'manager','content_editor'],
    submenu: [
      {
        path: '/app/agenti',
        title: 'Agenti',
        icon: 'check-circle',
        class: '',
        groupTitle: false,
        isVisibleFor: ['administrator', 'manager','content_editor'],
        submenu: []
      }
    ]
  },
  {
    path: '',
    title: 'Magazzino',
    icon: 'box',
    class: 'menu-toggle',
    groupTitle: false,
    isVisibleFor: ['administrator', 'manager','content_editor'],
    submenu: [
      {
        path: '/app/lotti',
        title: 'Lotti',
        icon: 'check-circle',
        class: '',
        groupTitle: false,
        isVisibleFor: ['administrator', 'manager','content_editor'],
        submenu: [],
      },
      {
        path: '/app/carenze',
        title: 'Carenze',
        icon: 'check-circle',
        class: '',
        groupTitle: false,
        isVisibleFor: ['administrator', 'manager','content_editor'],
        submenu: [],
      }
    ]
  },{
    path: '',
    title: 'Amministrazione',
    icon: 'box',
    class: 'menu-toggle',
    groupTitle: false,
    isVisibleFor: ['administrator', 'manager','content_editor'],
    submenu: [
      {
        path: '/app/billing-transfers',
        title: 'Fatturazione',
        icon: 'check-circle',
        class: '',
        groupTitle: false,
        isVisibleFor: ['administrator', 'manager','content_editor'],
        submenu: [],
      },
      {
        path: '/app/provvigioni-agenti',
        title: 'Provvigioni Agenti',
        icon: 'check-circle',
        class: '',
        groupTitle: false,
        isVisibleFor: ['administrator'],
        submenu: [],
      }
    ]
  }
]