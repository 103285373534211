export class User {
  id: number;
  username: string;
  displayName: string;
  phone: string;
  email: string;
  role: any;
  firstName: string;
  lastName: string;
  brand?: string
}
