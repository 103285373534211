<mat-accordion>
  <div class="materialTableHeader rounded-1" style="border: solid black 1px;">
    <div class="d-flex gap-3 align-content-center align-items-center p-3 mb-3">
      <h2>{{title}}</h2>
      <div *ngIf="formUrl">
        <ul class="tbl-export-btn">
          <li class="tbl-header-btn">
            <div matTooltip="ADD">
              <a [routerLink]="addUrl" [queryParams]="queryParams ? { from: queryParams.value } : ''">
                <button mat-mini-fab color="primary">
                  <mat-icon class="col-white">add</mat-icon>
                </button>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <mat-expansion-panel [expanded]="displayFilters" class="m-1">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Filtri tabella
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row py-3 bg-white" [formGroup]="form" style="width: 100%;" (keyup.enter)="applyFilters()">
        <div class="col-xs col-sm-6 mb-3 d-flex" *ngFor="let filter of _configuration, index as i">
          <mat-form-field class="w-100 no-grow" appearance="outline" subscriptSizing="dynamic"
            *ngIf="filter.type === 'number' || filter.type === 'text'">
            <mat-label [ngClass]="filter.labelClassList">{{filter.label}}</mat-label>
            <input [ngClass]="filter.inputClassList" matInput [formControlName]="filter.name"
              [attr.type]="filter.type" />
            <mat-icon [ngClass]="filter.iconClassList" matSuffix>{{filter.icon}}</mat-icon>
          </mat-form-field>
          <mat-form-field class="w-100 no-grow" appearance="outline" *ngIf="filter.type === 'select'"
            subscriptSizing="dynamic">
            <mat-label [ngClass]="filter.labelClassList">{{filter.label}}</mat-label>
            <!-- Select without dynamic load -->
            <mat-select *ngIf="!filter.dynamicLoad" class="no-grow" [ngClass]="filter.inputClassList"
              [formControlName]="filter.name" [multiple]="filter.multipleSelection"
              (openedChange)="!filter.disableFilter ? setFocus($event, 'select-filter-' + i) : ''">
              <mat-select-filter *ngIf="!filter.disableFilter" [array]="selectFilters[i].list" [displayMember]="'label'"
                (filteredReturn)="selectFilters[i].filteredList = $event;"
                id="{{'select-filter-' + i}}"></mat-select-filter>
              <mat-option *ngFor="let option of selectFilters[i].filteredList" [value]="option.value">{{ option.label }}
              </mat-option>
            </mat-select>

            <!-- Select with dynamic load -->
            <mat-select *ngIf="filter.dynamicLoad" class="no-grow" [ngClass]="filter.inputClassList"
              [formControlName]="filter.name" [multiple]="filter.multipleSelection"
              (openedChange)="setFocus($event, 'select-filter-' + i)">
              <mat-select-filter #selectFilter id="{{ 'select-filter-' + i }}" [array]="selectFilters[i].list"
                [displayMember]="'label'" (filteredReturn)="setupSearch(i)"></mat-select-filter>
              <cdk-virtual-scroll-viewport cdkScrollable appendOnly id="{{ 'select-scroller-' + i }}" itemSize="48"
                minBufferPx="200" style="height: 200px">
                <div *cdkVirtualFor="let option of selectFilters[i].filteredList">
                  <mat-option [value]="option.value" [disabled]="option.value === 'noResults'">{{ option.label
                    }}</mat-option>
                </div>
              </cdk-virtual-scroll-viewport>
            </mat-select>

          </mat-form-field>
          <mat-form-field class="w-100 no-grow" appearance="outline" *ngIf="filter.type === 'date'">
            <mat-label>{{filter.label}}</mat-label>
            <input matInput [matDatepicker]="picker" [formControlName]="filter.name">
            <mat-hint>gg/mm/aaaa</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="w-100 no-grow" appearance="outline" *ngIf="filter.type === 'date_range'">
            <mat-label>{{filter.label}}</mat-label>
            <mat-date-range-input [formGroupName]="filter.name" [rangePicker]="picker" class="text-black">
              <input matStartDate formControlName="start" placeholder="Data inizio">
              <input matEndDate formControlName="end" placeholder="Data fine">
            </mat-date-range-input>
            <mat-hint class="text-uppercase text-black">gg/mm/aaaa – gg/mm/aaaa</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <!-- <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error> -->
          </mat-form-field>
          <div class="w-100" *ngIf="filter.type === 'number_range'">
            <mat-slider min="0" max="1000" [formGroupName]="filter.name" discrete style="width: 96%"
              [displayWith]="formatLabel">
              <input value="0" matSliderStartThumb formControlName="min">
              <input value="1000" matSliderEndThumb formControlName="max">
            </mat-slider>
            <mat-label style="color: black; display: block">{{filter.label}}</mat-label>
          </div>
          <mat-checkbox class="w-100 align-self-center" [ngClass]="filter.inputClassList"
            [formControlName]="filter.name" *ngIf="filter.type === 'checkbox'">{{filter.label}}</mat-checkbox>
        </div>
      </div>
      <div class="row w-100 d-flex align-items-center justify-content-center gap-3 p-3 bg-white">
        <button style="width: fit-content" mat-flat-button color="primary" (click)="resetFilters()">Cancella
          Filtri</button>
        <button style="width: fit-content" mat-flat-button color="primary" (click)="applyFilters()">Applica
          Filtri</button>
      </div>
    </mat-expansion-panel>
  </div>


</mat-accordion>