import { Component, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

import { DatePipe } from '@angular/common';

import { UrlService } from '../../_service/url.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-general-popup',
  templateUrl: './general-popup.component.html',
  styleUrls: ['./general-popup.component.sass'],
  providers: [DatePipe]
})
export class GeneralPopupComponent {
  kind: string;
  status: string;
  element: string;
  data = new Subject<{ action: string, kind?: string, label?: string, data?: any }>;
  popupData;

  constructor(
    public dialogRef: MatDialogRef<GeneralPopupComponent>,
    public cdref: ChangeDetectorRef,
    private url: UrlService
  ) {
    dialogRef.disableClose = true;

    this.data.subscribe(value => {
      if (value.action === 'update') {
        setTimeout(() => {
          this.dialogRef.close({
            action: 'update',
            kind: value.kind,
            status: false
          })
        }, 5000);
      }

      this.popupData = value;
      console.log(this.popupData);
    })
  }

  bindData(bindedData: { action: string, kind?: string, label?: string, data?: any }): void {
    console.log(bindedData)
    this.data.next(bindedData);
  }

  onNoClick(): void {
    this.dialogRef.close('onNoClick - close');
  }

  submitUserChoice(action?, kind?, choice?: boolean, result?: any): void {
    this.dialogRef.close({
      action: action ? action : null,
      kind: kind ? kind : null,
      status: choice ? choice : null,
      data: result ? result : null,
    });
  }
}
