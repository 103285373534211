<div class="file-drop-area">
  <button mat-raised-button color="primary" class="mx-2" (click)="fileInput.click()">Seleziona file</button>
  <button *ngIf="file && myFiles.length === 0 && allowFileOpening" mat-raised-button color="primary" class="mx-2"
    (click)="openFile()">Open file</button>

  <span class="file-msg" *ngFor="let file of myFiles">
    <div class="me-2">{{ file.name }}</div>
  </span>
  <span class="file-msg" *ngIf="file && myFiles.length === 0">
    <div class="me-2">{{ file.filename }}</div>
  </span>
  <input class="file-input" type="file" [multiple]=allowMultiple [accept]="allowedExtensions"
    (change)="onFileChange($event)" (dragover)="$event.preventDefault()" (drop)="$event.preventDefault()" #fileInput>
</div>